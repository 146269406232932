import React from 'react'
import { Box, Flex, Grid } from '@theme-ui/components';
import{fabric} from "fabric"
import { useCanvasValue } from '../../../../PhotoEditorBox/context'

export const addImageToCanvas = (canvas, asset, source) => {
  const image = new Image();
  image.crossOrigin = "anonymous";
  image.src = asset.assetURL;
  console.log('before image loaded', image);

  image.onload = () => {
    console.log('image loaded', image);
    const fabricImage = new fabric.Image(image, {
      left: 0,
      top: 0,
    });

    // if no nft image yet, treat as base image and resize canvas for it
    if (canvas.getObjects().length === 0) {
      fabricImage.set('selectable', false);

      let canvasWidth = window.innerWidth * 0.4;
      let ratio = image.naturalHeight / image.naturalWidth;
      let imageScale = canvasWidth / image.naturalWidth;
      let canvasHeight = canvasWidth * ratio;

      canvas.setWidth(canvasWidth);
      canvas.setHeight(canvasHeight);
      fabricImage.scale(imageScale);
      fabricImage.set("isBaseImage", true);
    } else {
      // scale new elements to 1/2 of current canvas, and center it
      let canvasWidth = canvas.getWidth();
      let imageWidth = image.naturalWidth;
      let ratio = canvasWidth / imageWidth / 2;

      canvas.setActiveObject(fabricImage);
      fabricImage.scale(ratio);
    }

    // FIXME: [NFTBAR-39] have the proper asset id
    fabricImage.set("dataNftName", asset.name);
    fabricImage.set("dataNftAddr", asset.contractAddress);
    fabricImage.set("dataNftTokenId", asset.tokenID);
    fabricImage.set("dataChainId", "1");
    fabricImage.set("subtype", `nft_${source}`);
    fabricImage.set("identifier", asset.name);

    canvas.add(fabricImage);
  };
};

const CharsPanel = (props, source, contractAddress, tokenID) => {
    const {canvas} = useCanvasValue()
    
    return props.assets
    ? (
      <Grid
      sx={{
        gap: "1rem",
        padding: "1rem 2rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="objects-list"
    >
      {props.assets.map((asset, index) => {
        return(
          <Flex key={index} sx={{ cursor: "pointer" }}>
            <Box sx={{ width: "150px" }} onClick={() => addImageToCanvas(canvas, asset, props.source)}>
              <img src={asset.assetURL} alt="img" style={{ width: "100%" }} />
            </Box>
          </Flex>
        );
      })}
    </Grid>
    )
    : null;
}

export default CharsPanel
