import React, { useState } from 'react'
import Modal from 'react-modal';
import { Flex, Grid, Input } from '@theme-ui/components'
import { useCanvasValue } from '../../../../PhotoEditorBox/context';
import { fabric } from "fabric";
import { modalStyles, textStyles } from "./modal.style.js";
import { Text, StyleSheet, Linking} from "react-native"

const UploadPanel = () => {
  const {canvas} = useCanvasValue();
  const [media, setMedia] = useState(null);
  const [showModel, setShowModel] = useState(false);
  const [modelContent, setModelContent] = useState("minting filter");

  const addImageToCanvas = (media) => {
    fabric?.Image?.fromURL(media, function (myImg) {
      myImg.set({
        type: "image",
      });
      canvas?.add(myImg);
      canvas?.setActiveObject(myImg);
    });
  };

  function openModal() {
    setShowModel(true);
  }

  function closeModal() {
    setShowModel(false);
  }

  const onMediaChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setMedia(URL.createObjectURL(img));
      openModal();
      window.mintNewFilter(
        (content) => {
          setModelContent(content);
        },
        (imageUrl) => {
          console.log(imageUrl);
        }
      );
      // pop up the alert and ask, do you want to upload this as a filter?
      // getBase64(img).then((base64) => {
      //   localStorage["fileBase64"] = base64;
      //   console.debug("file stored", base64);
      // });
    }
    
  };
    return (
        <>
      <Grid
        sx={{
          padding: "1rem 2rem",
        }}
        className="objects-list"
      >
        <Flex
          sx={{
            alignItems: "center",
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          <label
            htmlFor="file-upload"
            className="custom-file-upload"
            style={{
              textAlign: "center",
              background: "#13805A",
              paddingBlock: "13px",
              paddingInline: "50px",
              fontSize: "14px",
              color: "#fff",
              fontFamily: "work sans",
              borderRadius: "4PX",
              boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.25)",
              margin: "auto",
              marginTop: "70px",
            }}
          >
            Upload Filter
          </label>
          <Input
            id="file-upload"
            style={{ background: "#fff", display: "none" }}
            type="file"
            placeholder="Upload Media"
            onChange={onMediaChange}
          />
        </Flex>
        <Grid>
          <Flex
            sx={{
              width: " 200px",
              height: "300px",
            }}
            onClick={()=>addImageToCanvas(media)}
          >
            {media && (
              <img
                src={media}
                alt="media"
                style={{ width: "200px", height: "200px" }}
                id="stored_img"
              />
            )}
          </Flex>
        </Grid>
      </Grid>
      <Modal
        isOpen={showModel}
        style={modalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <Flex style={{flexDirection: "column"}}>
          <Text style={textStyles.baseText}>
            {modelContent}
          </Text>
          <button style={{marginBottom: 10}} onClick={closeModal}>close</button>
        </Flex>
      </Modal>
    </>
    )
}

export default UploadPanel
