import { Box, Input, Text, Button} from "theme-ui";
import { fabric } from "fabric";
import { useCanvasValue } from "../../../../PhotoEditorBox/context";
import { useState } from 'react'
import { genAsset } from './PanelItem.js'
import { addImageToCanvas } from './CharsPanel.js' 

function AnyNFTPanel() {
 const {canvas} = useCanvasValue()

 const [contractAddress, setContractAddress] = useState('');
 const [tokenID, setTokenID] = useState('');

  // const type = "StaticText";
  const addHeading = () => {
    const textbox = new fabric.Textbox(" Add a heading", {
      type: 'text',
      left: 50,
      top: 50,
      width: 200,
      fontSize: 26,
      fontWeight: 700,
    });
    canvas.add(textbox);
    canvas.setActiveObject(textbox);
  };

  const loadNFTWithInput = async () => {
    console.log("submit", contractAddress, tokenID);
    try {
        let asset = await genAsset(contractAddress, tokenID);
        addImageToCanvas(canvas, asset, 'any_nft');
    } catch (e) {
        console.error(e);
    }
  }
 
  return (
    <>
      <Box sx={{ padding: "0 2rem", color: "#fff", fontFamily: "Lexend" ,width:'100%', paddingBlockStart:"20px"}}>
        <Text>Contract Address</Text>
        <Input 
            value={contractAddress}
            onChange={(e) => {setContractAddress(e.target.value)}}
            placeholder="0xabcd...." />
        <Text>TokenID</Text>
        <Input 
            value={tokenID} 
            onChange={(e) => setTokenID(e.target.value)}
            placeholder="1"/>
        <Button sx={{
            marginTop:10,
            background: "#0E1318",
            fontWeight: "bold",
            }}
            onClick={loadNFTWithInput}>
            Load NFT
        </Button>
      </Box>
    </>
  );
}
export default AnyNFTPanel;
