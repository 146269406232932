import React from "react";
import { useState, useEffect } from "react";
import { fabric } from "fabric";
import { useCanvasValue } from "./context";
import { addImageToCanvas } from './../scense/Editor/Panels/PanelItems/CharsPanel.js' 

function Editor() {
  const { setCanvas, isMobile } = useCanvasValue();
  const [canvasObjects, setCanvasObjects] = useState([]);


  useEffect(() => {
    const canvas = new fabric.Canvas("canvas", {
      left: 50,
      top: 20,
      preserveObjectStacking: true,
      crossOrigin: "anonymous"
    });
    isMobile ? canvas.setWidth(400) : canvas.setWidth(600);
    isMobile ? canvas.setHeight(400) : canvas.setHeight(600);
    setCanvas(canvas);
  
    canvas.on('object:added', function(e) {
      setCanvasObjects(canvas.getObjects());
    });

    canvas.on('object:removed', function(e) {
      // setCanvasObjects(canvas.getObjects());
    });


    const event = new CustomEvent('canvas-loaded', {
      detail: {
        canvas: canvas
      }
    });
    window.canvas = canvas;
    window.dispatchEvent(event);
    console.log('adding image');
    addImageToCanvas(canvas, {assetURL: '/images/105_intro2.png'}, 'default_nft');

  // eslint-disable-next-line
  }, []);
  
  return (
    <div
      style={{
        // height: "1000px",
        // width: "100%",
        backgroundColor: "#F9F9F9",
      }}
    >
      {
        canvasObjects.length === 0
        ? <div style={{
            margin: "auto",
            textAlign: "center",
            fontWeight: "bold",
            marginTop: 20,
          }}>
          {"Select a NFT as base to get started"}
        </div>
        : null
      }
      <canvas id="canvas" ></canvas>
    </div>
  );
}

export default Editor;
