import { Button, Flex } from '@theme-ui/components'
// import jsPDF from 'jspdf';
import React, { useState } from 'react'
import { Text, StyleSheet, Linking} from "react-native"
import Modal from 'react-modal';
import { useCanvasValue } from '../../../../PhotoEditorBox/context';
import { logEventWithPayload, getCanvasItemsLoggingPayload } from '../../../../Logger.js';
import { modalStyles, textStyles } from "./modal.style.js";

const modelStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
  },
};

const ExportPanel = () => {
    const {canvas}= useCanvasValue();
    const [modelContent, setModelContent] = useState("minting asset");
    const [showModel, setShowModel] = useState(false);
    const [newAssetURL, setNewAssetURL] = useState(null);

    // const pdf = new jsPDF("p", "in", [6.3, 9],'smart');
    // const downloadPdf=()=>{
    //     var imgData = canvas.toDataURL("image/jpeg", 1.0);
    //     pdf.addImage(imgData, 'JPEG', 0, 0);
    //     pdf.save("design.pdf");
    // }
    const downloadImage=()=>{
        const dataURL = canvas.toDataURL({
            format: 'png',
            multiplier: 2,
       });
       const link = document.createElement('a');
       link.download = 'image.png';
       link.href = dataURL;
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link);
       logEventWithPayload('export_image', {
         canvasItems: getCanvasItemsLoggingPayload(canvas),
       });
    }

    const mint=()=>{
      console.log(canvas);
      window.canvas = canvas;
      openModal()
      window.mintNewNFT(
        (content) => {
          setModelContent(content);
        },
        (imageUrl) => {
          setNewAssetURL(imageUrl);
        }
      );
      logEventWithPayload('mint_nft', {
        canvasItems: getCanvasItemsLoggingPayload(canvas),
      });
    }

    function openModal() {
      setShowModel(true);
    }
  
    function closeModal() {
      setShowModel(false);
    }

    function shareToTwitter() {
      let baseURL = new URL("https://twitter.com/intent/tweet");
      baseURL.searchParams.append("hashtag", "NFTBartender");
      baseURL.searchParams.append("url", newAssetURL);
      baseURL.searchParams.append("text", `Check out my new work!`);
      Linking.openURL(baseURL);
    }

    return (
        <div style={{marginBlockStart:"140px"}}>
          {/* <Flex sx={{marginBlock:"20px"}}>
            <Button sx={{color:"#fff",backgroundColor:"#fff",fontWeight:"bold",cursor:"pointer"}} onClick={downloadPdf}>Export as Pdf</Button>
          </Flex> */}
          <Flex>
            <Button  sx={{color:"#13805A",backgroundColor:"#fff",fontWeight:"bold",cursor:"pointer"}} onClick={downloadImage}>Export as png</Button>
          </Flex>
          <Flex sx={{marginBlock:"20px"}}>
            <Button sx={{color:"#fff",backgroundColor:"#13805A",fontWeight:"bold",cursor:"pointer"}} onClick={mint}>Mint as new nft</Button>
          </Flex>

          <Modal
            isOpen={showModel}
            style={modalStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <Flex style={{flexDirection: "column"}}>
              <Text style={textStyles.baseText}>
                {modelContent}
              </Text>
              <button style={{marginBottom: 10}} onClick={closeModal}>close</button>
              {
                newAssetURL
                ? <button onClick={shareToTwitter}>Share to Twitter</button>
                : null
              }
            </Flex>
          </Modal>
      </div>
    )
}

export default ExportPanel
