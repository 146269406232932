import { Box, Grid, Flex } from "theme-ui";
import { fabric } from "fabric";
import { useCanvasValue } from "../../../../PhotoEditorBox/context";
function TextPanel() {
 const {canvas} = useCanvasValue()
  // const type = "StaticText";
  const addHeading = () => {
    const textbox = new fabric.Textbox(" Add a heading", {
      type: 'text',
      left: 50,
      top: 50,
      width: 200,
      fontSize: 26,
      fontWeight: 700,
    });
    canvas.add(textbox);
    canvas.setActiveObject(textbox);
  };
 
 

  const addSubheading = () => {
    const textbox = new fabric.Textbox(" Add a subheading", {
      type:"text",
      left: 50,
      top: 50,
      width: 200,
      fontSize: 21,
      fontWeight: 500,
    });
    canvas.add(textbox).setActiveObject(textbox);
   
  };
  const addParagraph = () => {
    const textbox = new fabric.Textbox("Add a pargraph text", {
      type:"text",
      left: 50,
      top: 50,
      width: 200,
      fontSize: 18,
      fontWeight: 300,
    });
    canvas.add(textbox).setActiveObject(textbox);
  };
  const addSmallText = () => {
    const textbox = new fabric.Textbox("   Add a small text", {
      type:'text',
      left: 50,
      top: 50,
      width: 200,
      fontSize: 14,
      fontWeight: 300,
    });
    canvas.add(textbox).setActiveObject(textbox);
  };
  return (
    <>
      
      <Box sx={{ padding: "0 2rem", color: "#fff", fontFamily: "Lexend" ,width:'100%', paddingBlockStart:"20px"}}>
        <Grid sx={{ gridTemplateRows: "50px 50px 50px" }}>
          <Flex
            sx={{
              paddingLeft: "1rem",
              fontSize: "26px",
              alignItems: "center",
              background: "rgba(255,255,255,0.1)",
              fontWeight: 700,
              fontFamily: "work sans",
              width:'100%'
            }}
            onClick={addHeading}
          >
            Add a heading
          </Flex>
          <Flex
            sx={{
              paddingLeft: "1rem",
              fontSize: "21px",
              alignItems: "center",
              background: "rgba(255,255,255,0.1)",
              fontWeight: 500,
              fontFamily: "work sans",
              width:'100%'
            }}
            onClick={addSubheading}
          >
            Add a subheading
          </Flex>
          <Flex
            sx={{
              paddingLeft: "1rem",
              fontSize: "18px",
              alignItems: "center",
              background: "rgba(255,255,255,0.1)",
              fontWeight: 300,
              fontFamily: "work sans",
              width:'100%'
            }}
            onClick={addParagraph}
          >
            Add a pargraph text
          </Flex>
          <Flex
            sx={{
              paddingLeft: "1rem",
              fontSize: "14px",
              alignItems: "center",
              background: "rgba(255,255,255,0.1)",
              fontWeight: 300,
              fontFamily: "work sans",
              paddingBlock: "14px",
              width:'100%'
            }}
            onClick={addSmallText}
          >
            Add a small text
          </Flex>
        </Grid>
      </Box>
    </>
  );
}
export default TextPanel;
