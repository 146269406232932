import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";

function createCanvasItemLoggingPayload(target) {
    let dict = {};
  
    [
      'dataNftAddr', 
      'dataNftTokenId', 
      'dataNftName', 
      'subtype', 
      'identifier', 
      'type', 
      'text',
      'isBaseImage'
    ]
      .forEach(attribute => target[attribute] && (dict[attribute] = target[attribute]));
  
    return dict;
}

export function getCanvasItemsLoggingPayload(canvas) {
    return canvas.getObjects().map(target => createCanvasItemLoggingPayload(target));
}

export function initLogger() {
    const firebaseConfig = {
        apiKey: "AIzaSyC6FnXLwnGiTMDgfRMn193YNAUdvjNffwQ",
        authDomain: "nftbartender.firebaseapp.com",
        projectId: "nftbartender",
        storageBucket: "nftbartender.appspot.com",
        messagingSenderId: "794077354184",
        appId: "1:794077354184:web:b9f21b60b91696c44aadfd",
        measurementId: "G-7G2YQTQBGH"
    };
    initializeApp(firebaseConfig);
}

export function configureCanvasLogging(canvas) {
    canvas.on('text:editing:exited', function(e) {
        logEventWithPayload('change_text', createCanvasItemLoggingPayload(e.target));
    });
  
    canvas.on('object:added', function(e) {
        logEventWithPayload('add_canvas_item', createCanvasItemLoggingPayload(e.target));
    });
  
    canvas.on('object:removed', function(e) {
        logEventWithPayload('remove_canvas_item', createCanvasItemLoggingPayload(e.target));
    });
}

export function logEventWithPayload(event, payload) {
    logEvent(getAnalytics(), event, payload);
    console.log(event, payload);
}
