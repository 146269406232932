import { useCanvasValue } from "../../../PhotoEditorBox/context";
import "../../styles/styles.scss";
function ClosePanel({ closePanel, panelOpen }) {
  const { isMobile } = useCanvasValue();
  return (
    <div
      style={{
        opacity: `${panelOpen ? 1 : 0}`,
        transition: "all 0.6s",
        top: `${isMobile && "-1px"}`,
        right: `${isMobile && "56%"}`,
      }}
      onClick={closePanel}
      className="panel-item-close"
    >
      {isMobile ? (
        <svg
          width="64"
          height="4"
          viewBox="0 0 64 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="64" height="4" rx="2" fill="#EBEBEB" />
        </svg>
      ) : (
        <>
          <div className="c2">
            <svg
              width="7"
              height="13"
              viewBox="0 0 7 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.76679 0.189797C6.64108 0.067857 6.47434 0 6.30098 0C6.12762 0 5.96088 0.067857 5.83517 0.189797L0.386051 5.56156C0.264075 5.67996 0.166944 5.82224 0.100576 5.98019C0.0342081 6.13814 0 6.30851 0 6.48039C0 6.65227 0.0342081 6.82232 0.100576 6.98027C0.166944 7.13822 0.264075 7.2805 0.386051 7.39891L5.87461 12.8103C6.12916 13.0606 6.54152 13.0637 6.79988 12.8167C6.86243 12.7578 6.91247 12.6866 6.94689 12.6072C6.98131 12.5278 6.99939 12.4421 6.99999 12.3552C7.00058 12.2683 6.9837 12.1822 6.95037 12.1023C6.91704 12.0223 6.86799 11.9503 6.80625 11.8906L1.7835 6.93933C1.72246 6.88012 1.67386 6.80886 1.64064 6.72986C1.60743 6.65085 1.59029 6.56605 1.59029 6.48007C1.59029 6.39409 1.60743 6.30898 1.64064 6.22997C1.67386 6.15097 1.72246 6.07971 1.7835 6.0205L6.76615 1.10831C6.82717 1.04916 6.87576 0.977788 6.90896 0.898838C6.94217 0.819887 6.9593 0.734983 6.9593 0.649055C6.9593 0.563126 6.94217 0.478223 6.90896 0.399272C6.87576 0.320321 6.82717 0.248948 6.76615 0.189797"
                fill="white"
              />
            </svg>
          </div>
          <div className="c1">
            <svg
              width="82"
              height="91"
              viewBox="0 0 82 91"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d)">
                <rect
                  x="12"
                  y="18"
                  width="46"
                  height="55"
                  rx="8"
                  fill="#353E3B"
                />
              </g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47.7668 39.1898C47.6411 39.0679 47.4743 39 47.301 39C47.1276 39 46.9609 39.0679 46.8352 39.1898L41.3861 44.5616C41.2641 44.68 41.1669 44.8222 41.1006 44.9802C41.0342 45.1381 41 45.3085 41 45.4804C41 45.6523 41.0342 45.8223 41.1006 45.9803C41.1669 46.1382 41.2641 46.2805 41.3861 46.3989L46.8746 51.8103C47.1292 52.0606 47.5415 52.0637 47.7999 51.8167C47.8624 51.7578 47.9125 51.6866 47.9469 51.6072C47.9813 51.5278 47.9994 51.4421 48 51.3552C48.0006 51.2683 47.9837 51.1822 47.9504 51.1023C47.917 51.0223 47.868 50.9503 47.8063 50.8906L42.7835 45.9393C42.7225 45.8801 42.6739 45.8089 42.6406 45.7299C42.6074 45.6509 42.5903 45.5661 42.5903 45.4801C42.5903 45.3941 42.6074 45.309 42.6406 45.23C42.6739 45.151 42.7225 45.0797 42.7835 45.0205L47.7661 40.1083C47.8272 40.0492 47.8758 39.9778 47.909 39.8988C47.9422 39.8199 47.9593 39.735 47.9593 39.6491C47.9593 39.5631 47.9422 39.4782 47.909 39.3993C47.8758 39.3203 47.8272 39.2489 47.7661 39.1898"
                fill="white"
              />
              <defs>
                <filter
                  id="filter0_d"
                  x="0"
                  y="0"
                  width="82"
                  height="91"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="6" />
                  <feGaussianBlur stdDeviation="9" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </>
      )}
    </div>
  );
}

export default ClosePanel;
