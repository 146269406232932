import React from "react";
import { Box, Flex, Grid } from "@theme-ui/components";
import {fabric} from "fabric"
import { useCanvasValue } from "../../../../PhotoEditorBox/context";

const EffectPanel = () => {
  
    const {canvas} = useCanvasValue()
    const addImageToCanvas = (pic) => {
        fabric?.Image?.fromURL(pic, function (myImg) {
          myImg.set({
            type: "image",
            scaleX:.33,
            scaleY:.33
          });
          myImg.set("identifier", pic);
          myImg.set("subtype", "effect");
          canvas.add(myImg);
          canvas.setActiveObject(myImg);
        });
      };

  let effectList = ['hat', 'sun', 'crystals', 'oracle_bone', 'tiles', 'snake_eye', 'lakeside', 'zen', 'red_lightning', 'old_spill'];

  return (
    <Grid
      sx={{
        gap: "1rem",
        padding: "1rem 2rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="objects-list"
    >
     {effectList.map(function (pic, index) {
       const src = `${process.env.PUBLIC_URL}/images/${pic}.png`;
       return(
          <Flex key={index} sx={{ cursor: "pointer" }}>
            <Box sx={{ width: "150px" }} onClick={() => addImageToCanvas(src)}>
              <img src={src} alt="img" style={{ width: "100%" }} />
            </Box>
          </Flex>
       );
      })}
    </Grid>
  );
};

export default EffectPanel;
