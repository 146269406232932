import React from "react";

const DeleteIcon = () => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9879 4.5733H17.7013V3.32094C17.7013 2.47585 17.0141 1.78857 16.169 1.78857H11.8315C10.9864 1.78857 10.2992 2.47585 10.2992 3.32094V4.5733H6.01262C4.83153 4.5733 3.86426 5.53548 3.86426 6.72167V8.8293C3.86426 9.1093 4.09335 9.33839 4.37335 9.33839H5.43735V25.0998C5.43735 26.286 6.39953 27.2482 7.58062 27.2482H20.4148C21.601 27.2482 22.5632 26.286 22.5632 25.0998V9.3333H23.6272C23.9072 9.3333 24.1363 9.10421 24.1363 8.82421V6.71657C24.1363 5.53548 23.1741 4.5733 21.9879 4.5733ZM11.3173 3.32094C11.3173 3.03585 11.5464 2.80676 11.8315 2.80676H16.169C16.4541 2.80676 16.6832 3.03585 16.6832 3.32094V4.5733H11.3173V3.32094ZM21.545 25.0948C21.545 25.7209 21.041 26.2249 20.4148 26.2249H7.58062C6.95953 26.2249 6.45553 25.7209 6.45553 25.0948V9.3333H21.545V25.0948ZM23.1181 8.31512H4.88244V6.71657C4.88244 6.09548 5.38644 5.58639 6.01262 5.58639H21.993C22.6141 5.58639 23.1232 6.09039 23.1232 6.71657V8.31512H23.1181Z"
        fill="#FF0000"
      />
      <path
        d="M15.6643 24.1885C15.9443 24.1885 16.1734 23.9594 16.1734 23.6794V11.8787C16.1734 11.5987 15.9443 11.3696 15.6643 11.3696C15.3843 11.3696 15.1552 11.5987 15.1552 11.8787V23.6794C15.1552 23.9594 15.3843 24.1885 15.6643 24.1885ZM12.3348 24.1885C12.6148 24.1885 12.8439 23.9594 12.8439 23.6794V11.8787C12.8439 11.5987 12.6148 11.3696 12.3348 11.3696C12.0548 11.3696 11.8258 11.5987 11.8258 11.8787V23.6794C11.8258 23.9594 12.0498 24.1885 12.3348 24.1885ZM18.9988 24.1885C19.2788 24.1885 19.5079 23.9594 19.5079 23.6794V11.8787C19.5079 11.5987 19.2788 11.3696 18.9988 11.3696C18.7188 11.3696 18.4898 11.5987 18.4898 11.8787V23.6794C18.4898 23.9594 18.7188 24.1885 18.9988 24.1885ZM9.0003 24.1885C9.2803 24.1885 9.50939 23.9594 9.50939 23.6794V11.8787C9.50939 11.5987 9.2803 11.3696 9.0003 11.3696C8.7203 11.3696 8.49121 11.5987 8.49121 11.8787V23.6794C8.49121 23.9594 8.7203 24.1885 9.0003 24.1885Z"
        fill="#FF0000"
      />
    </svg>
  );
};

export default DeleteIcon;
