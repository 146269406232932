import { Button, Flex } from "@theme-ui/components";
import React from "react";

const Navbar = () => {
  return (
    <Flex
      sx={{
        fontFamily: "Work Sans",
        height: "80px",
        background: "#fff",
        paddingBlock: "16px",
        paddingInline: "80px",
        justifyContent: "space-between",
        width: "100%",
        position: "sticky",
        top:"0",
        left:"0",
        zIndex:11111,
        borderBottom: "1px solid rgba(19, 128, 90, 0.22)",
        "@media screen and (max-width: 600px)": {
          paddingInline: "20px",
        },
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          "@media screen and (max-width: 600px)": {
            width: "161px",
          },
        }}
      >
        <h1>NFTBartender</h1>
      </Flex>
      <Flex>
          <Flex id="prepare">
            <Button id="btn-connect" sx={{backgroundColor:"blue"}}>
                Connect With
            </Button>
          </Flex>
          <Flex id="connected" style={{display: "none"}}>
            <Button id="btn-disconnect" sx={{backgroundColor:"blue"}}>
                Disconnect
            </Button>
          </Flex>
      </Flex>
    </Flex>
  );
};

export default Navbar;
