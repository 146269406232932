import { Text, StyleSheet, Linking} from "react-native"

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
  },
};

const textStyles = StyleSheet.create({
  baseText: {
    paddingBottom: 10
  },
  titleText: {
    fontSize: 20,
    fontWeight: "bold"
  }
});


export {modalStyles, textStyles};