import { useEffect, useState } from "react";
import { useCanvasValue } from "./PhotoEditorBox/context";
import Editor from "./scense/Editor";
import { configureCanvasLogging, initLogger } from "./Logger.js";

function App() {
  const {setisMobile} = useCanvasValue()
  const [state, setState] = useState({
    mobileView: false,
  });
  // eslint-disable-next-line no-unused-vars
  const { mobileView } = state;


  const loadScript = (src) => {
    return new Promise(function (resolve, reject) {
      var script = document.createElement('script');
      script.src = src;
      script.type="module";
      script.addEventListener('load', function () {
        resolve();
      })
      script.addEventListener('error', function (e) {
        reject(e);
      })
      document.body.appendChild(script);
    })
  }

  useEffect(() => {
    async function fetchData() {
      const setResponsiveness = () => {
        return window.innerWidth < 1000
          ? setState(
              (prevState) => ({ ...prevState, mobileView: true }),
              setisMobile(true)
            )
          : setState(
              (prevState) => ({ ...prevState, mobileView: false }),
              setisMobile(false)
            );
      };
      setResponsiveness();
      window.addEventListener("resize", () => setResponsiveness());

      await loadScript(`${process.env.PUBLIC_URL}/js/connect_wallet.js`);
      await loadScript(`${process.env.PUBLIC_URL}/js/main.js`);
      await loadScript(`${process.env.PUBLIC_URL}/js/mint.js`);
      await loadScript(`${process.env.PUBLIC_URL}/js/filters.js`);
      // await loadScript(`${process.env.PUBLIC_URL}/js/gif.js`);
    };
    fetchData();

    // Init Logger
    initLogger();

    let canvas = window.canvas;
    if (canvas) {
      configureCanvasLogging(canvas);
    } else {
      window.addEventListener("canvas-loaded", (e) => {
        configureCanvasLogging(e.detail.canvas);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
   <Editor id="main"/>
  );
}

export default App;
