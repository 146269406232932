import React from "react";
import { Box, Flex } from "@theme-ui/components";
import { useState } from "react";
import { SketchPicker } from "react-color";
import { textPicker } from "./img";
import useStyles from "./toolbox.style";
import { useCanvasValue } from "../../../PhotoEditorBox/context";

function TextToolbox() {
  const { canvas, setFont, font } = useCanvasValue();
  const clasess = useStyles();

  const [dropdown, setDropdown] = useState({
    displayColorPicker: false,
  });
  const [options, setOptions] = useState({
    backgroundColor: "#ffffff",
  });

  const handleClick = () => {
    setDropdown({
      ...dropdown,
      displayColorPicker: !dropdown.displayColorPicker,
    });
  };

  const handleClose = () => {
    setDropdown({ ...dropdown, displayColorPicker: false });
  };

  const popover = {
    position: "absolute",
    zIndex: 2,
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const onColorChange = (color) => {
    setOptions({ ...options, backgroundColor: color.hex });
    canvas.getActiveObject() &&
      canvas.getActiveObject().set({
        fill: color.hex,
      });

    canvas.renderAll();
  };

  const onChangeText = (e) => {
    setFont(e.target.value);
    canvas.getActiveObject() &&
      canvas.getActiveObject().set({
        fontFamily: font,
      });
    canvas.renderAll();
  };
  return (
    <Flex
      sx={{
        height: 54,
        alignItems: "center",
        padding: "0 1rem",
        justifyContent: "space-between",
        "@media screen and (max-width: 600px)": {
          minWidth: "unset",
        },
      }}
    >
      <Flex
        sx={{
          display: "flex",
          flexDirection: "row",
          "@media screen and (max-width: 600px)": {
            minWidth: "unset",
          },
        }}
      >
        <select
          id="font-family"
          onChange={onChangeText}
          className={clasess.fontSelect}
        >
          <option value="arial">Arial</option>
          <option value="helvetica" defaultValue>
            Helvetica
          </option>
          <option value="myriad pro">Myriad Pro</option>
          <option value="delicious">Delicious</option>
          <option value="verdana">Verdana</option>
          <option value="georgia">Georgia</option>
          <option value="courier">Courier</option>
          <option value="comic sans ms">Comic Sans MS</option>
          <option value="impact">Impact</option>
          <option value="monaco">Monaco</option>
          <option value="optima">Optima</option>
          <option value="hoefler text">Hoefler Text</option>
          <option value="plaster">Plaster</option>
          <option value="engagement">Engagement</option>
        </select>

        <div style={{ width: "1px", backgroundColor: "#dadada" }}></div>

        <Flex
          sx={{
            marginInline: "17px",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative", marginInlineEnd: "7px" }}>
            <div style={{ cursor: "pointer" }} onClick={handleClick}>
              {options.backgroundColor === "#ffffff" ? (
                <img
                  style={{ height: "30px", display: "flex" }}
                  src={textPicker}
                  alt="color picker"
                />
              ) : (
                <Box
                  sx={{
                    background: options.backgroundColor,
                    height: 30,
                    width: 30,
                  }}
                />
              )}
            </div>

            {dropdown.displayColorPicker ? (
              <div style={popover}>
                <div style={cover} onClick={handleClose} />
                <SketchPicker
                  color={options.backgroundColor}
                  onChange={onColorChange}
                />
              </div>
            ) : null}
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TextToolbox;
